
import moment from "moment"
import ItemTable from "./item-table.vue"
export default {
    components: {
        ItemTable
    },
    props: {
        visibleEdit: Boolean,
        data_id: String
    },
    data() {
        return {
            selection: [],
            activeKey: "1",
            data: null
        }
    },
    watch: {
        data_id: {
            handler(val) {
                this.data = null;
                this.load_detail(val);
            }
        }
    },
    computed: {
        visible: {
            get() {
                return this.visibleEdit
            },
            set(val) {
                this.$emit("update:visibleEdit", val)
            }
        },
        address(){
            return this.data.address || null
        }
    },
    methods: {
        dateFormat(val) {
            return moment(val).format("YYYY-MM-DD hh:mm:ss");
        },
        load_detail(id) {
            if (!id) {
                return
            }

            this.$http.get(`/user/store_order/get_store_order_info/${id}`).then(res => {
                if (res.data.code == 0) {
                    this.data = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
                console.error(e);
            })
        }
    }
}
